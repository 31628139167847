<template>
  <div class="table-wrapper">
    <slot name="table-navigation">
      <NoSearchTableNavigation />
    </slot>
    <slot name="data-table">
      <n-data-table
        :columns="Object.values(filteredData)"
        :data="items"
        :single-line="false"
        size="small"
        class="table"
        :loading="isFetching"
        :row-props="onClickRowEvent"
        :scroll-x="1500"
        @update:sorter="handleSorterChange"
      />

      <ContextMenu />
    </slot>
    <slot name="pagination">
      <NoSearchPagination />
    </slot>
  </div>
</template>

<script>
import { NDataTable } from 'naive-ui';
import { ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import { useRoute } from 'vue-router';
import { useQuery } from '@/app/use/router';
import { useColumn } from '@/app/use/columns';
import NoSearchTableNavigation, {
  totalItems,
} from '@/app/components/base/NoSearchTableNavigation';
import NoSearchPagination from '@/app/components/base/NoSearchPagination';
import ContextMenu from '@/app/components/base/ContextMenu';
import { useContextMenu } from '@/app/use/contextMenu';
//import { useUrl } from '@/app/router';

export default {
  name: 'DataTable',
  components: {
    ContextMenu,
    NoSearchTableNavigation,
    NoSearchPagination,
    NDataTable,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    fetchFunction: {
      type: Function,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    sortBy: {
      type: String,
      required: false,
      default: 'id',
    },
  },
  setup(props) {
    const route = useRoute();
    const isFetching = ref(true);
    const items = ref([]);
    const { search, update, pagination, columns } = useQuery();
    const { columnsData, setColumnData, filterColumns } =
      useColumn();
    const { showContextMenu } = useContextMenu();
    const filteredData = ref([]);

    setColumnData(props.columns);

    const displayData = data => {
      items.value = data.items;
      totalItems.value = data.total;
    };

    const loadComponentData = debounce(() => {
      isFetching.value = true;
      props
        .fetchFunction({
          id: route.params.id,
          search: search.value,
          pagination: pagination.value,
        })
        .then(displayData)
        .finally(() => {
          isFetching.value = false;
        });
    }, 300);

    const handleFilterChange = () => {
      filteredData.value = filterColumns(columns.value);
    };

    const handleSorterChange = sorter => {
      update({
        sortBy: sorter.columnKey,
        descending: sorter.order,
      });
      loadComponentData();
    };

    watch(
      () => route.query,
      async () => {
        if (route.name == props.routeName) {
          loadComponentData();
        }
      },
      { immediate: true }
    );

    watch(
      () => route.query.columns,
      async () => {
        if (route.name == props.routeName) {
          handleFilterChange();
        }
      },
      { immediate: true }
    );

    /* --------------------------------- */
    const onClickRowEvent = row => {
      return {
        onClick: () => {
          showContextMenu(event, row);
        },
      };
    };
    return {
      items,
      isFetching,
      totalItems,
      handleSorterChange,
      columnsData,
      filteredData,
      onClickRowEvent,
    };
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  padding: 1rem;
  min-height: 200px;
}

.table {
  margin: 1rem 0;
}
</style>
