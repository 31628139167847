<template>
  <div class="no-search-data-table-navigation">
    <p class="total-items-paragraph">Total items: {{ totalItems }}</p>
    <slot name="table-columns-filter"
      ><div><TableColumnsFilter /></div
    ></slot>
  </div>
</template>

<script>
import { ref } from 'vue';
import TableColumnsFilter from '@/app/components/base/TableColumnsFilter';

export const totalItems = ref(0);
export default {
  name: 'NoSearchTableNavigation',
  components: { TableColumnsFilter },
  setup() {
    return {
      totalItems,
    };
  },
};
</script>

<style scoped lang="scss">
.no-search-data-table-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.total-items-paragraph {
  text-align: left;
  font-weight: 700;
}

@media (max-width: 767px) {
  .data-table-navigation {
    display: block;
  }
}
</style>
