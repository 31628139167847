<template>
  <div class="container-fluid align-left">
    <div class="top-container-wrapper">
      <p class="list-title">Subscription Details</p>
    </div>

    <div class="buttons-wrapper">
      <Button
        type="button"
        color="caution"
        :disabled="
          isFetching ||
          !fields ||
          fields.status == 'CREATED' ||
          fields.status == 'INACTIVE'
        "
        @click="showCancelConfirm()"
        >Cancel Subscription</Button
      >
      <Button type="button" color="caution" @click="showBlacklistConfirm()"
        >Blacklist User</Button
      >
      <Button
        type="button"
        color="warning"
        :disabled="isFetching || !fields || fields.status != 'ACTIVE'"
        @click="showPauseConfirm()"
        >Pause Subscription</Button
      >
      <Button
        type="button"
        color="success"
        :disabled="
          isFetching ||
          !fields ||
          fields.status == 'CREATED' ||
          fields.status == 'ACTIVE'
        "
        @click="showReactivateConfirm()"
        >Reactivate Subscription</Button
      >
      <Button type="button" color="light" @click="redirectBack()"
        >Back To Search</Button
      >
    </div>
    <div v-if="!isFetching && fields && fields.id" class="entity-fields">
      <div>
        <p class="entity-field-label">
          <strong>ID :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.id }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>UUID :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.uuid }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Category :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.category }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Status :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.status }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Email :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.email }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Card :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.card }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Offer :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.offer }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Company :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.company }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Domain :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.domain }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>3DS :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.threeDS }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Country :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.country }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Currency :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.currency }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>BillingSchema :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.billingSchema }}
        </p>
      </div>
      <div v-if="fields.cycleStartDay">
        <p class="entity-field-label">
          <strong>CycleStartDay :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.cycleStartDay }}
        </p>
      </div>
      <div v-if="fields.rebillHour">
        <p class="entity-field-label">
          <strong>RebillHour :</strong>
        </p>
        <p class="entity-field-value">{{ fields.rebillHour }}:00</p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>RecurringTariff :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.recurringTariff }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>CycleTariffLimit :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.cycleTariffLimit }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>CreatedAt :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.createdAt }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>StatusChangedAt :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.statusChangedAt }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>BilledAmount :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.billedAmount.toFixed(2) }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>GrossRevenue :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.grossRevenue.toFixed(2) }}
        </p>
      </div>
      <div v-if="fields.statusSource">
        <p class="entity-field-label">
          <strong>StatusSource :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.statusSource }}
        </p>
      </div>
    </div>
    <div class="top-container-wrapper">
      <p class="list-title">Payments</p>
    </div>
    <div>
      <NoSearchDataTable
        :columns="paymentColumns"
        :fetch-function="fetchSubscriptionPayments"
        :route-name="'Subscription'"
      />
    </div>
  </div>
</template>

<script>
import { useDetails } from '@/app/use/views';
import {
  fetchSubscriptionById,
  fetchSubscriptionPayments,
  cancelSubscription,
  blacklistSubscription,
  pauseSubscription,
  reactivateSubscription,
} from '@/app/api/subscriptions';
import { refundPaymentByUuid } from '@/app/api/payments';
import { redirectToRoute } from '@/app/router';
import { ref } from 'vue';
import Button from '@/app/components/base/Button';
import { useModal } from '@/app/use/modal';
import { success as showSuccessSnackbar } from '@/app/use/snackbar';
import NoSearchDataTable from '@/app/components/base/NoSearchDataTable';
import { useContextMenu } from '@/app/use/contextMenu';
import router from '@/app/router/index';
import Icon from '@/app/components/base/Icon';
import { h } from 'vue';
import { useLoadingModal } from '@/app/use/loadingModal';

const paymentColumns = [
  {
    title: 'Id',
    key: 'id',
    sorter: () => {},
    width: 50,
    isVisible: true,
  },
  {
    title: 'UUID',
    key: 'uuid',
    isVisible: false,
  },
  {
    title: 'ExtTransactionId',
    key: 'extTransactionId',
    isVisible: false,
  },
  {
    title: 'AdditionalId',
    key: 'additionalId',
    isVisible: false,
  },
  {
    title: 'CreatedAt',
    key: 'createdAt',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'Category',
    key: 'category',
    isVisible: false,
  },
  {
    title: 'Currency',
    key: 'currency.code',
    render(row) {
      return row.currencyDto.code;
    },
    isVisible: true,
  },
  {
    title: 'Amount',
    key: 'amount',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'RefundedAmount',
    key: 'refundedAmount',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'RefundsCount',
    key: 'refundsCount',
    isVisible: false,
  },
  {
    title: 'LastRefundedAt',
    key: 'lastRefundedAt',
    isVisible: false,
  },
  {
    title: 'RefundSource',
    key: 'refundSource',
    isVisible: false,
  },
  {
    title: 'Fraud',
    key: 'fraud',
    sorter: () => {},
    render(row) {
      return h(Icon, {
        name: row.fraud ? 'check_circle' : '',
      });
    },
    isVisible: true,
  },
  {
    title: 'Chargeback',
    key: 'chargeback',
    sorter: () => {},
    render(row) {
      return h(Icon, {
        name: row.chargeback ? 'check_circle' : '',
      });
    },
    isVisible: true,
  },
  {
    title: 'ChargebackAt',
    key: 'chargebackAt',
    isVisible: false,
  },
];

export default {
  name: 'SubscriptionDetails',
  components: { Button, NoSearchDataTable },
  setup() {
    const { setMenuItems } = useContextMenu();

    const contextMenuItems = [
      {
        label: 'Refund full remaining amount',
        key: item => {
          useModal({
            onAccept() {
              useLoadingModal().showLoading();
              refundPaymentByUuid({ uuid: item.value.uuid })
                .then(() => {
                  showSuccessSnackbar('Payment successfully refunded');
                  router.go();
                })
                .finally(() => {
                  useLoadingModal().hideLoading();
                });
            },
            header: 'Confirm',
            body: 'Are you sure you want to fully refund this payment?',
          }).show();
        },
      },
      {
        label: 'Manage',
        key: item => {
          redirectToRoute({
            name: 'Payment',
            params: { id: item.value.id },
          });
        },
      },
    ];

    setMenuItems(contextMenuItems);

    const { id, loadComponentData, isFetching } = useDetails({
      fetchFunction: fetchSubscriptionById,
    });

    let fields = ref(null);
    loadComponentData().then(async data => {
      fields.value = data;
    });

    const showCancelConfirm = useModal({
      onAccept() {
        useLoadingModal().showLoading();
        cancelSubscription(fields.value.uuid)
          .then(() => {
            showSuccessSnackbar('Subscription successfully cancelled');
            loadComponentData().then(async data => {
              fields.value = data;
            });
          })
          .finally(() => {
            useLoadingModal().hideLoading();
          });
      },
      header: 'Confirm',
      body: 'Are you sure you want to cancel this subscription?',
    }).show;

    const showBlacklistConfirm = useModal({
      onAccept() {
        useLoadingModal().showLoading();
        blacklistSubscription(fields.value.uuid)
          .then(() => {
            showSuccessSnackbar(
              'User(email and card) successfully blacklisted'
            );
          })
          .finally(() => {
            useLoadingModal().hideLoading();
          });
      },
      header: 'Confirm',
      body: 'Are you sure you want to blacklist this user?',
    }).show;

    const showPauseConfirm = useModal({
      onAccept() {
        useLoadingModal().showLoading();
        pauseSubscription(fields.value.uuid)
          .then(() => {
            showSuccessSnackbar('Subscription successfully paused');
            loadComponentData().then(async data => {
              fields.value = data;
            });
          })
          .finally(() => {
            useLoadingModal().hideLoading();
          });
      },
      header: 'Confirm',
      body: 'Are you sure you want to pause this subscription?',
    }).show;

    const showReactivateConfirm = useModal({
      onAccept() {
        useLoadingModal().showLoading();
        reactivateSubscription(fields.value.uuid)
          .then(() => {
            showSuccessSnackbar('Subscription successfully reactivated');
            loadComponentData().then(async data => {
              fields.value = data;
            });
          })
          .finally(() => {
            useLoadingModal().hideLoading();
          });
      },
      header: 'Confirm',
      body: 'Are you sure you want to reactivate this subscription?',
    }).show;

    const redirectBack = () => router.go(-1);

    return {
      id,
      isFetching,
      fields,
      redirectBack,
      showCancelConfirm,
      showBlacklistConfirm,
      showPauseConfirm,
      showReactivateConfirm,
      paymentColumns,
      contextMenuItems,
      fetchSubscriptionPayments,
    };
  },
};
</script>

<style scoped lang="scss">
.list-title {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
}

.top-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
</style>
